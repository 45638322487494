import type { ComponentProps, FocusEvent, KeyboardEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import type { TBoxSide, TOptional, TSideAlign } from '@/types/common';

import SocialShare from '@/components/Icon/SocialShare';
import ShareLinkSet from '@/components/ShareLinkSet';
import useShortLink from '@/components/ShareLinkSet/hooks/useShortLink';
import Balloon from '@/components/elements/Balloon';
import ContextModal, { type TRealign } from '@/components/elements/ContextModal';
import tryHandleEscape from '@/ducks/a11y/helpers/tryHandleEscape';
import tryHandleMoveFocus from '@/ducks/a11y/helpers/tryHandleMoveFocus';
import { FocusTrapAttr } from '@/ducks/a11y/types';
import { useUIResource } from '@/ducks/common/resources';
import getOppositeSide from '@/helpers/getOppositeSide';
import useOpenable from '@/hooks/useOpenable';

import './ShareButton.scss';

// Use --share-button-* css variables on the parent node(s) to tune the appearance

export const getDirection = (base: TOptional<HTMLElement>, other: TOptional<HTMLElement>): TOptional<boolean> => {
  if (base && other) {
    const mask = base?.compareDocumentPosition(other);
    if (mask & Node.DOCUMENT_POSITION_PRECEDING) return true;
    else if (mask & Node.DOCUMENT_POSITION_FOLLOWING) return false;
  }
};

export const setTabIndexes = (parent: TOptional<HTMLElement | null>, tabIndex: number): TOptional<HTMLElement[]> => {
  const buttons = parent?.querySelectorAll<HTMLElement>('.ShareButton__links button');
  buttons?.forEach((node) => {
    node.tabIndex = tabIndex;
  });
  if (buttons?.length) return Array.from(buttons);
};

export const ARIA_OPENER = 'Social Share';
export const ARIA_POPUP_STATUS = 'Social Share, pop-up button, expanded';
export const SELECTOR_FIRST_BUTTON = '.ShareLinkSet :nth-last-child(4) .ShareLinkSet-Item button';
export const SELECTOR_LAST_BUTTON = '.ShareLinkSet :nth-child(4) .ShareLinkSet-Item button';

type TPassage = 'entrance' | 'exit';

type TProps = {
  className?: string;
  link: string;
  // isShortLink - a part of ShareLinkSet
  modalAlign?: TSideAlign;
  modalSide?: TBoxSide;
  // onShare - a part of ShareLinkSet
  // sailingData - a part of ShareLinkSet
  tabIndex?: number;
} & Pick<ComponentProps<typeof ShareLinkSet>, 'isShortLink' | 'onShare' | 'sailingData'>;

const ShareButton = ({
  className,
  isShortLink,
  link,
  modalAlign = 'center',
  modalSide = 'bottom',
  onShare,
  sailingData,
  tabIndex,
}: TProps) => {
  const [canAnnounceStatus, setCanAnnounceStatus] = useState<boolean>();
  const switchCanAnnounceStatus = useCallback((isOpen: boolean) => {
    if (isOpen) setCanAnnounceStatus(true);
  }, []);

  const { close, isOpened, toggle } = useOpenable(false, switchCanAnnounceStatus);
  const openerRef = useRef<HTMLButtonElement>(null);
  const passageRef = useRef<TPassage>();
  const [beakSide, setBeakSide] = useState<TBoxSide>();
  const [beakAlign, setBeakAlign] = useState<TSideAlign>();
  const shortLink = useShortLink(link, isOpened && isShortLink);
  const title = useUIResource('SocialLinks.heading.itinerary.new');

  useEffect(() => setBeakSide(getOppositeSide(modalSide)), [modalSide]);
  useEffect(() => setBeakAlign(modalAlign), [modalAlign]);

  const onRealign = useCallback(
    (realign?: TOptional<TRealign>) => {
      const nextSide = getOppositeSide(realign?.side || modalSide);
      const nextAlign = realign?.align || modalAlign;
      if (nextSide !== beakSide) setBeakSide(nextSide);
      if (nextAlign !== beakAlign) setBeakAlign(nextAlign);
    },
    [beakAlign, beakSide, modalAlign, modalSide],
  );

  const onClose = useCallback((byKeyboard?: boolean) => {
    close();
    if (byKeyboard) openerRef.current?.focus();
  }, []);

  const onOpenerFocusBlur = useCallback((event: FocusEvent) => {
    const isIncome = event.type === 'focus';
    const root = openerRef.current?.parentNode as HTMLElement;
    const trapContainer = root.closest<HTMLElement>(`[${FocusTrapAttr.ROOT}]`);
    const directAttr = trapContainer?.getAttribute(FocusTrapAttr.DIRECT);
    const isDirect = directAttr ? directAttr === 'true' : getDirection(root, event.relatedTarget as HTMLElement);
    if (isDirect !== undefined) passageRef.current = isDirect === isIncome ? 'entrance' : 'exit';
  }, []);

  const onOpenerKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isOpened && tryHandleEscape(event, close)) return;
      const { key, shiftKey } = event;
      if (key === 'Tab' && isOpened) {
        const root = openerRef.current?.parentNode as HTMLElement;
        const isExit = passageRef.current === 'exit';
        if (shiftKey === isExit) {
          event.preventDefault();
          const buttons = setTabIndexes(root, 0);
          if (buttons?.length) buttons[shiftKey ? buttons.length - 1 : 0]!.focus();
        } else {
          const trapContainer = root.closest<HTMLElement>(`[${FocusTrapAttr.ROOT}]`);
          if (trapContainer) tryHandleMoveFocus(event, { container: trapContainer, keyGroups: ['tab'] as const });
        }
      }
    },
    [isOpened],
  );

  const oneModalKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key, shiftKey, target } = event;
      if (key === 'Tab') {
        const button = target as HTMLElement;
        if (button.matches(shiftKey ? SELECTOR_FIRST_BUTTON : SELECTOR_LAST_BUTTON)) {
          event.preventDefault();
          openerRef.current?.focus();
          passageRef.current = shiftKey ? 'entrance' : 'exit';
          setTabIndexes(button.closest<HTMLElement>('.ShareButton__links'), -1);
        }
      }
      if (canAnnounceStatus) setCanAnnounceStatus(false);
    },
    [canAnnounceStatus],
  );

  return (
    <div className={cn('ShareButton', className, { _open: isOpened })}>
      <button
        aria-expanded={isOpened}
        aria-haspopup="listbox"
        aria-label={ARIA_OPENER}
        className="ShareButton__opener"
        data-focus-trap-disable={isOpened ? 'escape,tab' : undefined}
        disabled={!shortLink}
        onBlur={onOpenerFocusBlur}
        onClick={toggle}
        onFocus={onOpenerFocusBlur}
        onKeyDown={onOpenerKeyDown}
        ref={openerRef}
        tabIndex={tabIndex || 0}
        type="button"
      >
        <SocialShare />
      </button>
      <ContextModal
        align={modalAlign}
        anchorRef={openerRef}
        className="ShareButton__modal"
        isOpened={isOpened}
        onClose={onClose}
        onRealign={onRealign}
        side={modalSide}
      >
        {isOpened && (
          <Balloon beakAlign={beakAlign} beakSide={beakSide} className="ShareButton__balloon">
            <ShareLinkSet
              ariaAnnounceStatus={canAnnounceStatus ? ARIA_POPUP_STATUS : undefined}
              autoFocus
              className="ShareButton__links"
              link={shortLink}
              onKeyDown={oneModalKeyDown}
              onShare={onShare}
              sailingData={sailingData}
              title={title}
            />
          </Balloon>
        )}
      </ContextModal>
    </div>
  );
};

export default ShareButton;
