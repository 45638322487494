import React, { type ReactHTML } from 'react';

type TProps = {
  className?: string;
  content: string;
  id?: string;
  node?: keyof ReactHTML;
};

const HtmlContent = React.memo(({ className, content, id, node: Node = 'div' }: TProps) => (
  <Node className={className} dangerouslySetInnerHTML={{ __html: content }} id={id} />
));

HtmlContent.displayName = 'HtmlContent';

export default HtmlContent;
