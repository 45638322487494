import { type HTMLAttributes, type ReactNode, useCallback, useState } from 'react';

import cn from 'classnames';

import type { TSailingData } from '@/infra/types/voyageInfo/sailing';
import type { TOptional } from '@/types/common';

import Tick from '@/components/Icon/Tick';
import Status from '@/ducks/a11y/components/Status';
import delay from '@/helpers/delay';
import useAutoFocus from '@/hooks/useAutoFocus';
import useClickOutside from '@/hooks/useClickOutside';

import ClipboardButton from './Clipboard';
import EmailButton from './Email';
import FacebookButton, { ARIA_LABEL as FACEBOOK_ARIA_LABEL } from './Facebook';
import XTwitterButton from './XTwitter';
import { ShareType } from './constants';

import './style.scss';

export const TEXT_COPY_STATUS = 'Link copied';

export type TSailingDataSlice = Partial<Pick<TSailingData, 'emailShareBody' | 'emailShareSubject' | 'socialShare'>>;

type TProps = HTMLAttributes<HTMLDivElement> & {
  ariaAnnounceStatus?: string;
  autoFocus?: boolean;
  className?: string;
  isAltIcons?: TOptional<boolean>;
  isOmitClipboard?: TOptional<boolean>;
  isOmitCopyStatus?: TOptional<boolean>;
  isShortLink?: TOptional<boolean>;
  link?: string;
  onShare?: TOptional<(type: ShareType, url: string) => void>;
  sailingData?: TSailingDataSlice;
  title?: ReactNode;
};

const ShareLinkSet = ({
  ariaAnnounceStatus,
  autoFocus,
  className,
  isAltIcons,
  isOmitClipboard,
  isOmitCopyStatus,
  link,
  onShare,
  sailingData,
  title,
  ...restRootProps
}: TProps) => {
  const [isCopyDone, setIsCopyDone] = useState<boolean>();
  const copyDoneRef = useClickOutside<HTMLDivElement>(() => setIsCopyDone(false), !isCopyDone, true);
  const ref = useAutoFocus<HTMLDivElement>(autoFocus);
  const { emailShareBody, emailShareSubject, socialShare } = sailingData || {};

  const clickHandler = useCallback(
    async (type: ShareType) => {
      if (!link) return;
      await delay(0); // for Safari
      setIsCopyDone(type === ShareType.CLIPBOARD);
      onShare?.(type, link);
    },
    [link, onShare],
  );

  const firstAriaLabel = ariaAnnounceStatus ? `${ariaAnnounceStatus}. ${FACEBOOK_ARIA_LABEL}` : undefined;

  return (
    <div {...restRootProps} className={cn('ShareLinkSet', className, { _alt: isAltIcons })} ref={ref}>
      {!!title && <h3 className="ShareLinkSet__title">{title}</h3>}
      <ul className="ShareLinkSet__items">
        <li>
          <FacebookButton aria-label={firstAriaLabel} isAltIcon={isAltIcons} link={link} onClick={clickHandler} />
        </li>
        <li>
          <XTwitterButton isAltIcon={isAltIcons} link={link} onClick={clickHandler} template={socialShare} />
        </li>
        <li>
          <EmailButton
            isAltIcon={isAltIcons}
            link={link}
            onClick={clickHandler}
            templateBody={emailShareBody}
            templateSubject={emailShareSubject}
          />
        </li>
        {!isOmitClipboard && (
          <li>
            <ClipboardButton isAltIcon={isAltIcons} link={link} onClick={clickHandler} />
          </li>
        )}
      </ul>

      <Status
        className="ShareLinkSet__copy-status"
        isShown={Boolean(!isOmitClipboard && !isOmitCopyStatus && isCopyDone)}
        ref={copyDoneRef}
        srOnlyText={TEXT_COPY_STATUS}
      >
        {TEXT_COPY_STATUS}
        <Tick />
      </Status>
    </div>
  );
};

export default ShareLinkSet;
