import { useEffect } from 'react';

import { selectConfigServiceFeatureFlags, selectSettings } from '@/ducks/common/settings';
import { FILTER_TYPES } from '@/ducks/filters/constants';
import { selectFilterByName } from '@/ducks/filters/values';
import { loadUplift } from '@/infra/uplift/uplift';
import { useAppSelector } from '@/store';

export const useInitUplift = () => {
  const isUpliftEnabled = Boolean(useAppSelector(selectConfigServiceFeatureFlags)?.uplift);
  const upliftConfiguration = useAppSelector(selectSettings)?.uplift;
  const currencyCode = useAppSelector((state) => selectFilterByName(state, FILTER_TYPES.currencyCode));

  useEffect(() => {
    loadUplift({ config: upliftConfiguration, currencyCode, isEnabled: isUpliftEnabled });
  }, [isUpliftEnabled, upliftConfiguration, currencyCode]);
};
