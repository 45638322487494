import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { differenceInCalendarDays } from 'date-fns';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { Skeleton } from '@/components/Skeleton';
import { selectLookup } from '@/ducks/common/selectors';
import { selectSettings } from '@/ducks/common/settings';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { currentNYTime, parse } from '@/helpers/util/dateUtil';
import { imitateClickOnKeyEvent } from '@/hooks/useOnKeyDown';
import { buildTripInfo, isCurrencySupported, loadTripInfo } from '@/infra/uplift';
import tagmanager from '@/tagmanager';

import UpliftTooltipIcon from '../Icon/UpliftTooltipIcon';
import UIResource from '../UIResource';

import './Uplift.scss';

const {
  tracker: { createTracker },
  trackerConstants,
} = tagmanager;

const trackEvent = createTracker({});

const Uplift = ({
  cabinName,
  configServiceData,
  currencyCode,
  customIcon,
  flyout,
  insuranceAmount,
  isHorizontal,
  isLoadUpliftDisabled,
  isModalDisabled,
  isTaxesIncluded,
  isTooltipDisabled,
  lookup,
  onTrack,
  price,
  priceType,
  primarySailor,
  sailing,
  sailorData: { additionalSailors = [] },
  showOrFromLabel,
  trackerEventCategory,
  upliftPriceType,
}) => {
  const priceWithCents = Math.ceil(price * 100);
  const insuranceWithCents = insuranceAmount * 100;
  const [upliftPrice, setUpliftPrice] = useState('');
  const upliftElement = useRef();
  useEffect(() => {
    if (!isLoadUpliftDisabled) {
      const tripInfo = buildTripInfo(
        priceWithCents,
        insuranceWithCents,
        sailing,
        primarySailor,
        cabinName,
        additionalSailors,
      );
      setTimeout(loadTripInfo(tripInfo), 200);
    }
  }, [isLoadUpliftDisabled, price]);

  useEffect(() => {
    const upliftInterval = setInterval(() => {
      const label = upliftElement?.current?.innerText;
      if (upliftElement && label) {
        setUpliftPrice(label);
        clearInterval(upliftInterval);
      }
    }, 1000);
  }, []);
  const fromLabel = useMemo(() => {
    const label = <UIResource id={showOrFromLabel ? 'uplift.startPrice' : 'ItineraryRefinementCard.from'} />;
    return <>{label} </>;
  }, [showOrFromLabel]);

  if (
    !isCurrencySupported(currencyCode) ||
    !price ||
    price < get(configServiceData, `uplift.minimumPrice.${currencyCode}`) ||
    price > get(configServiceData, `uplift.maximumPrice.${currencyCode}`)
  ) {
    return null;
  }

  const minimumDays = get(configServiceData, `uplift.minimumDays.${currencyCode}`);
  if (sailing && minimumDays) {
    let sailingStartDate;
    if ('startDate' in sailing) {
      sailingStartDate = sailing.startDate;
    } else {
      sailingStartDate = sailing.embarkDate;
    }

    const currentDate = currentNYTime(get(lookup, 'serverISOtime'));
    const daysUntilSailing = differenceInCalendarDays(parse(sailingStartDate), currentDate);
    if (daysUntilSailing < minimumDays) {
      return null;
    }
  }

  const trackUplift = () => {
    const label = `${trackerConstants.EVENT_LABELS.UPLIFT_INFO}${flyout ? `|${flyout}` : ''}`;
    tagmanager.tracker.common.trackIconClick({
      category: trackerEventCategory,
      label,
    });
    if (!isModalDisabled) {
      trackEvent({ action: 'displayed', module: 'uplift-price-context-pop-up' });
    }

    // eslint-disable-next-line no-unused-expressions
    onTrack?.();
  };

  const onKeyDown = (e) => {
    imitateClickOnKeyEvent(e);
    trackUplift();
  };

  const currencySymbol = getSymbolFromCurrenciesData(currencyCode);

  const classes = classnames('uplift', {
    'uplift-font': showOrFromLabel,
  });

  const additionalProps = {};
  if (isModalDisabled) {
    additionalProps['data-up-disable-lightbox'] = true;
  }
  if (isTooltipDisabled) {
    additionalProps['data-up-disable-tooltip'] = true;
  }
  if (isTaxesIncluded) {
    additionalProps['data-up-taxes-included'] = true;
  }

  const dataUpPriceType = priceType === 'perSailor' ? 'per_person' : 'total';

  return (
    <span
      className={classes}
      data-up-price-model={dataUpPriceType}
      data-up-price-type={upliftPriceType}
      data-up-price-value={priceWithCents}
      onKeyDown={onKeyDown}
      onMouseDown={trackUplift}
      onTouchStart={trackUplift}
      role="button"
      tabIndex="0"
      // Need to spread props because if the prop is present with false value it still takes effect
      {...additionalProps}
    >
      {isHorizontal ? (
        <>
          <span className="uplift__from">{fromLabel}</span>
          <span className="modal-linkContainer">
            <span className="modal-link">
              {currencySymbol}
              <span className="uplift__price">
                <span className="price" data-up-from-currency-unit-major="">
                  <Skeleton inline width="20px" />
                </span>
                <span className="month">
                  <UIResource id="uplift.month" />
                </span>
              </span>
              <span className="tooltip" data-up-tooltip="">
                {customIcon || <UpliftTooltipIcon />}
              </span>
            </span>
          </span>
        </>
      ) : (
        <div aria-label={`See uplift financing pricing from ${currencySymbol}${upliftPrice}/month`} role="button">
          <span className="flex-price">
            {fromLabel}
            <span className="modal-link">
              {currencySymbol}
              <span>
                <span className="price" data-up-from-currency-unit-major="" ref={upliftElement}>
                  <Skeleton inline width="20px" />
                </span>
                <UIResource id="uplift.month" />
              </span>
            </span>
          </span>
          <span aria-label="uplift modal tooltip" className="tooltip" data-up-tooltip="">
            {customIcon || <UpliftTooltipIcon />}
          </span>
        </div>
      )}
    </span>
  );
};

Uplift.propTypes = {
  cabinName: PropTypes.string,
  configServiceData: PropTypes.shape({
    uplift: PropTypes.shape({
      maximumPrice: PropTypes.shape({}),
    }),
  }),
  currencyCode: PropTypes.string,
  customIcon: PropTypes.node,
  flyout: PropTypes.string,
  insuranceAmount: PropTypes.number,
  isHorizontal: PropTypes.bool,
  isLoadUpliftDisabled: PropTypes.bool,
  isModalDisabled: PropTypes.bool,
  isTaxesIncluded: PropTypes.bool,
  isTooltipDisabled: PropTypes.bool,
  lookup: PropTypes.shape({}).isRequired,
  onTrack: PropTypes.func,
  price: PropTypes.number,
  priceType: PropTypes.oneOf(['perCabin', 'perSailor']),
  primarySailor: PropTypes.shape({}),
  sailing: PropTypes.shape({
    embarkDate: PropTypes.string,
    startDate: PropTypes.string,
  }),
  showOrFromLabel: PropTypes.bool,
  trackerEventCategory: PropTypes.string,
  upliftPriceType: PropTypes.oneOf(['cruise_option', 'total']),
};

Uplift.defaultProps = {
  cabinName: '',
  configServiceData: {},
  currencyCode: 'USD',
  customIcon: '',
  flyout: null,
  insuranceAmount: 0,
  isHorizontal: false,
  isLoadUpliftDisabled: false,
  isModalDisabled: false,
  isTaxesIncluded: false,
  isTooltipDisabled: true,
  onTrack: null,
  price: '',
  priceType: 'perCabin',
  primarySailor: {},
  sailing: {},
  showOrFromLabel: true,
  trackerEventCategory: null,
  upliftPriceType: 'total',
};

const mapStateToProps = (state) => ({
  configServiceData: selectSettings(state),
  lookup: selectLookup(state),
  sailorData: get(state, 'voyagePlanner.sailorDetails.sailorData.data', {}),
});

export default connect(mapStateToProps)(Uplift);
